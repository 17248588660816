<template>
	<v-text class="mb-5">
		{{ t('songSelection.savePrompt') }}
	</v-text>
	<div class="flex-col-reverse flex sm:flex-row sm:items-center gap-4 justify-between">
		<tw-button
			@click="cancel"
			class="w-full ">
			{{ t('songSelection.cancel') }}
		</tw-button>
		<tw-button
			@click="save"
			class="w-full btn-brand">
			{{ t('songSelection.save') }}
		</tw-button>
	</div>
</template>

<script lang="ts">
	export default { name: 'ConfirmationCard' } // name the component
</script>

<script lang="ts" setup>
	import {
		Notification
	} from '@/types'

	// Language
	import {
		useI18n
	} from 'vue-i18n'
	
	const {t} = useI18n()

	// Define emits
	const emit = defineEmits([
		'closeSongsSave',
		'submitSongSave'
	])

	function cancel() {
		emit('closeSongsSave')
	}

	function save() {
		const notification: Notification = {
			title: t('songSelection.saveSongs'),
			subtitle: t('songSelection.saveSuccess'),
			icon: 0
		}
		emit('submitSongSave', notification)
	}

</script>