<template>
	<tw-modal
		:title="t('songSelection.saveSongs')"
		modal-max-width="sm:max-w-xs max-h-min"
		ref="confirmationModal">
		<ConfirmationCard 
			@close-songs-save="toggleSaveSongModal"
			@submit-song-save="(notification) => {saveSongs(notification)}" />
	</tw-modal>

	<tw-notification 
		v-if="showNotification" 
		@close="showNotification = false" 
		:title="notificationTitle" 
		:subtitle="notificationSubtitle" 
		:duration="2000" 
		:icon="notificationIcon" />
	

	<div class="sticky top-0 py-3 px-6 bg-white z-50">
		<h1 class="text-brand-600 text-3xl font-semibold">
			{{ jobDetails.displayName }}
		</h1>
		<div class="flex items-center justify-between">
			<v-text>
				{{ jobDetails.weddingDate }} - {{ $filters.hour24Time(jobDetails.weddingTime) }}
			</v-text>
			<div class="flex items-center justify-end gap-4">
				<tw-button 
					:disabled="confirmationModal.open"
					@click="toggleSaveSongModal"  
					class="btn-brand">
					{{ t('songSelection.saveSongs') }}
				</tw-button>
				<a
					@click="() => Auth.signOut()"
					href="#"
					class="link">
					{{ t('nav.signOut') }}
				</a>
			</div>
		</div>
	</div>


	<!-- Search filters -->
	<div class="sticky top-[98px] z-40 bg-white shadow-sm border-t border-gray-200 border-b">
		<form
			class="flex flex-col h-full w-full"
			@submit.prevent="getSongs">
			<!-- Mobile view -->
			<div
				class="sm:hidden md:block lg:hidden w-full h-full relative"
				id="mobile-search-input">	
				<SearchInput
					v-model="songSearchDetails.artist"
					:placeholder="t('searchFilter.artist')"
					aria-label="Artist"
					class="h-full w-full"
					@update:model-value="searchOnStroke">
					<template #icon>
						<UserIcon />
					</template>
				</SearchInput>
				<div class="absolute top-0 right-2 my-2">
					<v-button
						@click="toggleSearchFilterModal"
						plain>
						<ChevronDownIcon
							class="h-5 w-5 text-gray-400 transition-all duration-300"
							:class="{'rotate-180': openModal}"
							aria-hidden="true" />
					</v-button>
				</div>
			</div>

			<!-- Desktop view -->
			<div class="grid-cols-4 w-full justify-items-center h-full hidden sm:grid md:hidden lg:grid gap-px px-px">
				<div class="w-full h-full">	
					<SearchInput
						v-model="songSearchDetails.artist"
						:label="t('searchFilter.artist')"
						:placeholder="t('searchFilter.artist')"
						aria-label="Artist"
						class="h-full"
						@update:model-value="searchOnStroke">
						<template #icon>
							<UserIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full border-l border-gray">
					<SearchInput
						v-model="songSearchDetails.songName"
						:label="t('searchFilter.song')"
						:placeholder="t('searchFilter.song')"
						aria-label="Song Name"
						class="h-full"
						@update:model-value="searchOnStroke">
						<template #icon>
							<MusicalNoteIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full border-l border-gray">	
					<SearchInput
						v-model="songSearchDetails.albumName"
						:label="t('searchFilter.album')"
						:placeholder="t('searchFilter.album')"
						aria-label="Album Name"
						class="h-full"
						@update:model-value="searchOnStroke">
						<template #icon>
							<Square3Stack3DIcon />
						</template>
					</SearchInput>
				</div>
				<div class="w-full border-l border-gray">	
					<SearchInput
						v-model="songSearchDetails.genre"
						:label="t('searchFilter.genre')"
						:placeholder="t('searchFilter.genre')"
						aria-label="Genre"
						class="h-full"
						@update:model-value="searchOnStroke">
						<template #icon>
							<Square3Stack3DIcon />
						</template>
					</SearchInput>
				</div>
			</div>
		</form>
	</div>

	<!-- Song Grid-->
	<div
		v-if="hasFilterApplied"
		class="flex w-full justify-center">
		<div class="grid grid-cols-1 sm:grid-cols-[310px,310px] md:grid-cols-1 md:w-full lg:grid-cols-[310px,310px] lg:w-auto xl:grid-cols-[310px,310px,310px] 2xl:grid-cols-[310px,310px,310px,310px] 3xl:grid-cols-[310px,310px,310px,310px,310px] overflow-y-auto gap-4 p-4 sm:w-auto w-full">
			<SongResult
				v-show="loading"
				v-for="i in 17"
				:key="i"
				:loading="loading" />
			<template
				v-for="(song, songIndex) in songList"
				:key="songIndex">
				<Container
					class="hidden sm:block"
					tag="span"
					group-name="results"
					behaviour="copy"
					:get-child-payload="() => getSongIdByIndex(songIndex)"
					:should-animate-drop="shouldAnimateDrop"
					:on-drag-start="onDragStart"
					:on-drag-end="onDragEnd">
					<Draggable class="sm:max-w-[310px] md:max-w-full lg:max-w-[310px]">
						<SongResult 
							:class="['draggable-item', { 'opacity-50': draggingIndex === songIndex }]"
							:result="song"
							@loading="loadingSong"
							@play="playSong" />
					</Draggable>
				</Container>
				<div
					v-for="(song, songIndex) in songList"
					:key="songIndex"	
					class="block sm:hidden sm:max-w-[310px]">
					<SongResult
						class="overflow-hidden"
						:result="song"
						@loading="loadingSong"
						@play="playSong" />
				</div>
			</template>
		</div>
	</div>

	<!-- Placeholder text -->
	<div
		v-else
		class="max-w-150 mx-auto space-y-4 px-6 my-16">
		<img
			class="h-30 w-auto opacity-40 m-auto"
			src="@/assets/images/Crystal_Wedding_Logo_Submark_v2.svg"
			alt="Heartful Logo">
		<v-text>
			{{ t('placeholders.noFilters') }}
		</v-text>
		<v-text>
			{{ t('placeholders.noFilters2') }}
		</v-text>
		<v-text>
			{{ t('sidebar.cutoffDateMessageStart') }} {{ jobStore.job.numDaysUpdateSong }} {{ t('sidebar.cutoffDateMessageEnd') }}
		</v-text>
	</div>

	<!-- Song controls --> 
	<div class="fixed bottom-0 flex w-full right-0 z-50">
		<now-playing
			:loading="songLoading"
			:now-playing="nowPlayingSong" />
	</div>

	<!-- Search Filter Modal -->
	<div>
		<TransitionRoot
			as="template"
			:show="openModal">
			<Dialog
				as="div"
				class="relative z-50 sm:hidden md:block lg:hidden"
				@close="openModal = false">
				<div
					class="fixed max-h-fit inset-0 md:ml-[358px] flex w-full shadow-lg"
					:style="mobileFiltersTop">
					<TransitionChild
						as="template"
						enter="duration-300"
						enter-from="transform scale-95 opacity-0 max-h-0"
						enter-to="transform scale-100 opacity-100 max-h-96"
						leave="transition ease duration-300 transform"
						leave-from="transform scale-100 opacity-100 max-h-96 h-96"
						leave-to="transform scale-95 opacity-0 max-h-0">
						<DialogPanel class="relative top-0 flex w-full flex-1 dark:bg-gray-900">
							<div class="w-full bg-white px-px space-y-px shadow-xl">
								<div class="w-full border-t border-gray-200">
									<SearchInput
										v-model="songSearchDetails.songName"
										:placeholder="t('searchFilter.song')"
										aria-label="Song Name"
										@update:model-value="searchOnStroke">
										<template #icon>
											<MusicalNoteIcon />
										</template>
									</SearchInput>
								</div>
								<div class="w-full border-t border-gray-200">	
									<SearchInput
										v-model="songSearchDetails.albumName"
										:placeholder="t('searchFilter.album')"
										aria-label="Album Name"
										@update:model-value="searchOnStroke">
										<template #icon>
											<Square3Stack3DIcon />
										</template>
									</SearchInput>
								</div>
								<div class="w-full border-t border-gray-200">	
									<SearchInput
										v-model="songSearchDetails.genre"
										:placeholder="t('searchFilter.genre')"
										aria-label="Genre"
										@update:model-value="searchOnStroke">
										<template #icon>
											<Square3Stack3DIcon />
										</template>
									</SearchInput>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>
</template>

<script lang="ts">
	export default { name: 'SearchMain' } // name the component
</script>

<script setup lang="ts">
	// Components
	import NowPlaying from './components/NowPlaying.vue'
	import SearchInput from './components/SearchInput.vue'
	import SongResult from './components/SongResult.vue'

	// Auth
	import { 
		Auth
	} from '@/api/Auth'

	// Drag n drop
	import { 
		Container, 
		Draggable 
	} from 'vue3-smooth-dnd'

	import EventBus from '@/events' // events

	// Headless UI
	import {
		Dialog,
		DialogPanel,
		Menu,
		TransitionChild,
		TransitionRoot,
	} from '@headlessui/vue'

	// Icons
	import{
		UserIcon,
		MusicalNoteIcon,
		Square3Stack3DIcon
	} from '@heroicons/vue/20/solid'
	
	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Queries
	import{
		getJobByRecordId,
		getSongByAlbumDetails
	}from './queries'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'
	import { 
		localesStore
	} from '@/store/locales'

	// Types
	import{
		Job,
		FindParameters,
		Song,
		Album,
		Scene
	} from '@/types'
    
	// Vue
	import {
		computed,
		defineEmits,
		inject,
		onBeforeUnmount,
		onMounted,
		ref
	} from 'vue'
	import {
		now,
		useDebounceFn
	} from "@vueuse/core"
	import ConfirmationCard from './components/ConfirmationCard.vue'

	const emit = defineEmits([
		'updateCount'
	])

	const {t} = useI18n()

	// Variables
	const job = ref<Job>({} as Job)
	const songSearchDetails = ref({
		artist: '',
		songName: '',
		albumName: '',
		genre: ''
	})
	const fetchS3File: any = inject('fetchS3File')

	
	
	const hasFilterApplied = computed(() => {
		if(songSearchDetails.value.artist.length || songSearchDetails.value.songName.length || songSearchDetails.value.albumName.length || songSearchDetails.value.genre.length) return true
		// if(albumList.value?.length) return true
		return false
	})

	const albumList = ref<Album[]>([])
	const songList = ref<Song[]>([])

	const jobStore = _jobStore()
	const _localesStore = localesStore()
	const jobDetails = ref({
		displayName: "",
		weddingDate: "",
		weddingTime: "",
		scenes: []
	})

	// On Mount
	onMounted(() => {
		job.value = jobStore.job

		// Get Job Details
		getJobDetails()

		EventBus.on('play-song', async (payload) => {
			playSong(payload)
		})

		EventBus.on('change-language', async () => {
			await getJobDetails()
			getSongs()
			updateScenes()
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('play-song', null)
	})

	// Searching Functions
	async function getJobDetails() {
		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})

		let params: FindParameters = {
			query: [
				{'_kp_jobs_id': job.value.jobID}
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 
		try{
			let result = await getJobByRecordId(params)

			// Check for valid session id
			if(result?.messages[0]?.code !== '0') {
				Auth.signOut()
			}

			jobDetails.value = {
				displayName: result?.response?.data[0]?.fieldData?.name_bride_groom_display,
				weddingDate: result?.response?.data[0]?.fieldData?.zctDateFormatted,
				weddingTime: result?.response?.data[0]?.fieldData?.time_start,
				scenes: (result?.response?.data[0]?.portalData?.portal_scenes) as []
			}
		}
		catch(e) {
			console.log(e)
		}

	}

	const loading = ref(false)
	async function getSongs() {
		if(!hasFilterApplied.value) return
		loading.value = true
		if(songList.value) songList.value.length = 0

		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})

		let params: FindParameters = {
			query: [
				{
					'song__ALBUM__albumID::name_search': songSearchDetails.value.albumName,
					'song__ALBUM__albumID::genre': songSearchDetails.value.genre,
					'songs__ARTISTS__allArtistID::artist_search': songSearchDetails.value.artist,
					'name_search': songSearchDetails.value.songName
				}
			],
			sort: [
				{	fieldName: '_kf_albums_id', sortOrder: 'ascend' }
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 
		try {
			let result = await getSongByAlbumDetails(params)

			// Check for valid session id
	
			if(result?.messages[0]?.code !== '0' && result?.messages[0]?.code !== '401') {
				Auth.signOut()
			}
			
			songList.value = await Promise.all(result?.response?.data?.map(async (item: any) => {
				const signedUrl = await fetchS3File(item.fieldData['song__album__ALBUMS_INFO__albumID::s3JSONPicture'])
				const song: Song = {
					songId: item.fieldData._kp_songs_id,
					albumName: item.fieldData['song__ALBUM__albumID::name_display'],
					additionalArtist: item.fieldData.zctAllAdditionalArtistDisplay,
					artworkFile: item.fieldData['song__album__ALBUMS_INFO__albumID::s3JSONPicture'],
					genre: item.fieldData['song__ALBUM__albumID::genre'],
					mainArtist: item.fieldData.zctArtistDisplay,
					signedUrl: signedUrl,
					songName: item.fieldData.name_display,
					songFile: item.fieldData['song__SONGS_INFO__songID::s3JSONSong']
				}

				return song
			}))
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	}

	const searchOnStroke = useDebounceFn(async () => {
		return await getSongs()
	}, 800)

	const nowPlayingSong = ref({
		artist: '',
		songName: '',
		albumName: '',
		genre: '',
		artworkUrl: '',
		signedUrl: ''
	})

	function playSong(nowPlaying: {
		artist: '',
		songName: '',
		albumName: '',
		genre: '',
		artworkUrl: '',
		signedUrl: ''
	}) {
		nowPlayingSong.value = nowPlaying
	}

	const songLoading = ref(false)
	function loadingSong(loading: boolean) {
		songLoading.value = loading
	}

	// #region Drag and Drop

	/**
	 * @function getSongIdByIndex
	 * @param songIndex - The dragged item's song index from the album
	 */
	function getSongIdByIndex(songIndex: any) {
		const songId = songList.value[songIndex]?.songId
		const song = songList.value[songIndex]
		return {songIndex, song}
	}

	/**
	 * @function shouldAnimateDrop -  Set dropping animation, called by the target container to which the dragged item will be droppped
	 */
	function shouldAnimateDrop() {
		return false
	}

	const draggingIndex = ref(null)
	function onDragStart({ isSource, payload }) {
		if(!isSource) return
		// payload holds the item being dragged, find the index and apply class
		draggingIndex.value = payload.songIndex
	}

	function onDragEnd() {
		// Remove the dragging class when drag ends
		draggingIndex.value = null
	}

	// #endregion

	const openSearchFilterModal = ref<{open: boolean}>({open: false})
	const openModal = ref<boolean>(false)
	const mobileFiltersTop = ref("")

	function toggleSearchFilterModal() {
		const mobileSearchInput = document.getElementById("mobile-search-input")
		const mobileSearchInputBottom = mobileSearchInput?.getBoundingClientRect().bottom
		mobileFiltersTop.value = `top: ${mobileSearchInputBottom}px;`
		openSearchFilterModal.value.open = !openSearchFilterModal.value.open
		openModal.value = !openModal.value
	}

	const confirmationModal = ref<{open: boolean}>({open: false})
	function toggleSaveSongModal() {
		confirmationModal.value.open = !confirmationModal.value.open
	}
	
	const showNotification = ref(false)
	const notificationIcon = ref(0)
	const notificationTitle = ref('')
	const notificationSubtitle = ref('')
	
	function displayNotification(notification: Notification) {
		notificationIcon.value = notification.icon
		notificationTitle.value = notification.title
		notificationSubtitle.value = notification.subtitle
		showNotification.value = true
	}

	async function saveSongs(notification) {
		try {
			const jobId = jobStore.job?.jobID
			const scenesArray = jobStore.job?.jobDetails?.scenes
			const scenes = scenesArray?.map(scene => { return { sceneId: scene['job__JOBS_SCENES__jobID::_kp_jobs_scenes_id'], songId: scene['job__JOBS_SCENES__jobID::zctSongID'] }})
			const res = await Auth.submitSongsNoEmail(jobId, scenes)
			const scriptResult = JSON.parse(res?.response?.scriptResult)

			if(scriptResult.errorCode != 0) {
				const error = scriptResult.errorMessage
				throw error
			}
			displayNotification(notification)
			toggleSaveSongModal()
		}
		catch(e) {
			console.log(e)
			const notification: Notification = {
				title: t('songSelection.saveSongs'),
				subtitle: t('songSelection.saveError'),
				icon: 1
			}
			displayNotification(notification)
		}
		finally {
			toggleSaveSongModal()
		}
	}

	/**
	 * Update the scene songs to display in selected language
	 * @function updateScenes
	 */
	async function updateScenes() {
		const scenes = jobStore.job?.jobDetails?.scenes
		const jobDetailScenes = jobDetails.value.scenes
		let newScenes = jobDetailScenes.map((scene, i) => {
			const newSongId = scenes[i]['job__JOBS_SCENES__jobID::zctSongID']
			scene['job__JOBS_SCENES__jobID::zctSongID'] = newSongId
			return scene
		})

		jobStore.job.jobDetails.scenes = newScenes
	}

</script>