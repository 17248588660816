<template>
	<div>
		<h1 class="text-sm font-light text-gray-700 w-full">
			{{ t('login.title') }}
		</h1>
	</div>

	<form
		class="mt-8"
		@submit.prevent="login">
		<div class="grid grid-cols-2 gap-0 md:gap-4 gap-y-4">
			<div class="text-sm col-span-2 md:col-span-1">
				<tw-input
					v-model="weddingInfo.groomName"
					id="groom"
					:required="true"
					:label="t('login.groom')"
					class="text-md" />
			</div>
			<div class="text-sm col-span-2 md:col-span-1">
				<tw-input
					v-model="weddingInfo.brideName"
					id="bride"
					:required="true"
					:label="t('login.bride')"
					class="text-md" />
			</div>
			<div class="text-sm col-span-2 md:col-span-1">
				<tw-input
					v-model="weddingInfo.weddingDate"
					id="wedding-date"
					:required="true"
					type="date"
					:label="t('login.weddingDate')"
					class="text-md" />
			</div>
			<div class="flex w-full items-end col-span-2 md:col-span-1">
				<div class="w-1/2 md:w-[65%]">
					<tw-select
						v-model="weddingInfo.weddingTimeHour"
						aria-label="Reception Time (hours)"
						id="wedding-time-hours"
						required
						class="rounded-r-none"
						:placeholder="t('time.hour')"
						:options="timeListHours"
						:label="t('login.weddingTime')" />
				</div>
				<div class="w-1/2 md:w-[35%]">
					<tw-select
						v-model="weddingInfo.weddingTimeMinute"
						aria-label="Reception Time (minutes)"
						id="wedding-time-minutes"
						class="rounded-l-none -ml-[1px]"
						required
						hide-required
						:placeholder="t('time.minute')"
						:options="timeListMinutes" />
				</div>
			</div>
			<div class="text-sm col-span-2">
				<tw-input
					v-model="password"
					id="password"
					:required="true"
					type="password"
					:label="t('login.password')"
					:validation="t('login.passwordValidation')"
					class="text-md">
					<template #trailing_inline>
						<button
							type="button"
							@click="toggleShowPassword" 
							class="border-none bg-transparent hover:bg-transparent ">
							<EyeIcon
								v-if="!showPassword"
								class="h-5" />
							<EyeSlashIcon
								v-else
								class="h-5" />
						</button>
					</template>
				</tw-input>
			</div>
		</div>

		<div class="mt-8">
			<tw-button 
				type="submit" 
				class="btn-brand font-medium w-full"
				:disabled="loginLoading"
				:loading="loginLoading">
				{{ t('login.login') }}
			</tw-button>
			<div 
				v-show="loginError" 
				class="text-red-500 mt-3 col-span-2 text-center">
				<p>{{ t('login.invalidEmailPassword') }}</p>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: 'LoginForm' } // name the component
</script>

<script setup lang="ts">
	// Api
	import axios, {
		Axios
	} from 'axios'

	// Auth
	import { 
		Auth
	} from '@/api/Auth'
	
	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Queries
	import{
		getJobByRecordId
	}from '@/modules/search/queries'

	// Router
	import {
		useRouter,
		useRoute
	} from 'vue-router'

	// Store
	import { 
		localesStore
	} from '@/store/locales'
	import { 
		store as _jobStore
	} from '@/store/job'
	import { 
		store as _valueListStore
	} from '@/store/valueLists'

	// Types
	import { 
		FindParameters,
		LangTypes,
		JobDetail,
		ValueList,
		ValueListValue,
		WeddingDetail
	} from '@/types'

	// Vue
	import {
		inject,
		ref
	} from 'vue'

	const jobStore = _jobStore()
	const valueListStore = _valueListStore()
	const _localesStore = localesStore()

	const router = useRouter()
	const route = useRoute()

	const {t} = useI18n()

	const weddingInfo = ref({} as WeddingDetail)
	const password = ref<string>('')
	const loginLoading = ref(false)
	const loginError = ref(false)
	const i18n = inject<any>('i18n')

	function validate() {
		let isValid = true

		if(!weddingInfo.value.brideName) isValid = false
		else if(!weddingInfo.value.groomName) isValid = false
		else if(!weddingInfo.value.weddingDate) isValid = false
		else if(!weddingInfo.value.weddingTimeHour) isValid = false
		else if(!weddingInfo.value.weddingTimeMinute) isValid = false
		else if(!password.value) isValid = false

		return isValid
	}

	const jobDetails = ref({} as JobDetail)

	async function login() {
		loginLoading.value = true

		if(validate()) {
			let response = await Auth.signIn(weddingInfo.value, password.value)

			if (!response.authenticated) {
				loginError.value = true
				loginLoading.value = false
				return
			}

			const jobID = response?.job?.jobID
			await getJobDetails(jobID)
			
			jobStore.job.jobID = response?.job?.jobID
			jobStore.job.loggedIn = response?.job?.loggedIn
			jobStore.job.jobDetails = jobDetails.value
			loginLoading.value = false

			// handle redirects
			router.push(route?.query?.redirect ? String(route?.query?.redirect) : { name: 'Search' })
		}
		else {
			loginError.value = true
		}

		loginLoading.value = false
	}

	async function getJobDetails(jobID: string) {
		const scriptParams = JSON.stringify({
			language: _localesStore.getFmLanguage
		})

		const recordId: FindParameters = {
			query: [
				{'_kp_jobs_id': jobID}
			],
			'script.prerequest': 'Set Language',
			'script.prerequest.param': scriptParams
		} 
		try{
			let result = await getJobByRecordId(recordId)
			jobDetails.value = {
				displayName: result?.response?.data[0]?.fieldData?.name_bride_groom_display,
				weddingDate: result?.response?.data[0]?.fieldData?.zctDateFormatted,
				weddingTime: result?.response?.data[0]?.fieldData?.time_start,
				scenes: (result?.response?.data[0]?.portalData?.portal_scenes) as []
			}
			jobStore.job.numDaysUpdateSong = result?.response?.data[0]?.fieldData?.['jobs__PREFERENCES__cart::changeSongsNumDaysBeforeJob']

			return result
		}
		catch(e) {
			console.log(e)
		}

	}
	// #endregion

	const timeListHours = [
		{
			label: '00',
			value: '00'
		},
		{
			label: '01',
			value: '01'
		},
		{
			label: '02',
			value: '02'
		},
		{
			label: '03',
			value: '03'
		},
		{
			label: '04',
			value: '04'
		},
		{
			label: '05',
			value: '05'
		},
		{
			label: '06',
			value: '06'
		},
		{
			label: '07',
			value: '07'
		},
		{
			label: '08',
			value: '08'
		},
		{
			label: '09',
			value: '09'
		},
		{
			label: '10',
			value:'10'
		},
		{
			label: '11',
			value:'11'
		},
		{
			label: '12',
			value:'12'
		},
		{
			label: '13',
			value:'13'
		},
		{
			label: '14',
			value:'14'
		},
		{
			label: '15',
			value:'15'
		},
		{
			label: '16',
			value:'16'
		},
		{
			label: '17',
			value:'17'
		},
		{
			label: '18',
			value:'18'
		},
		{
			label: '19',
			value:'19'
		},
		{
			label: '20',
			value:'20'
		},
		{
			label: '21',
			value:'21'
		},
		{
			label: '22',
			value:'22'
		},
		{
			label: '23',
			value:'23'
		},
	]

	const timeListMinutes = [
		{
			label: '00',
			value: '00'
		},
		{
			label: '05',
			value: '05'
		},
		{
			label: '10',
			value: '10'
		},
		{
			label: '15',
			value: '15'
		},
		{
			label: '20',
			value: '20'
		},
		{
			label: '25',
			value: '25'
		},
		{
			label: '30',
			value: '30'
		},
		{
			label: '35',
			value: '35'
		},
		{
			label: '40',
			value: '40'
		},
		{
			label: '45',
			value: '45'
		},
		{
			label: '50',
			value: '50'
		},
		{
			label: '55',
			value: '55'
		},
	]
	
	const showPassword = ref(false)

	function toggleShowPassword() {
		const passwordField = document.querySelector('#password')

		if(passwordField?.getAttribute('type') === 'password') {
			passwordField.setAttribute('type', 'text')
			showPassword.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			showPassword.value = false
		}
	}
</script>

<style>
	.rounded-r-none #listBoxButton{
		border-bottom-right-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	.rounded-l-none #listBoxButton{
		border-bottom-left-radius: 0px !important;
		border-top-left-radius: 0px !important;
	}
</style>