<template>
	<div>
		<div class="flex justify-between">
			<label
				:for="id"
				class="block text-sm font-medium text-gray-700 sr-only">{{ label }}</label>
		</div>
		<div class="flex relative rounded-none shadow-sm h-full">
			<div class="flex relative w-full">
				<div class="flex absolute inset-y-0 left-0">
					<div
						v-if="$slots.icon"
						class="pl-3 flex items-center pointer-events-none">
						<span
							class="h-5 w-5 text-gray-400"
							aria-hidden="true"><slot name="icon" /></span>
					</div>
				</div>
				<input 
					:type="type ?? 'text'" 
					:name="label" 
					:id="id"
					class="pl-10 flex-1 min-w-0 block focus:ring-brand-500 focus:border-brand-500 w-full sm:text-sm border-transparent rounded-none py-4"
					:placeholder="placeholder"
					@input="onInput"
					:value="modelValue">
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SearchInput' } // name the component
</script>

<script setup lang="ts">
	import {
		nextTick
	} from "vue"

	const props = defineProps({
		modelValue: {
			type: [String, Number],
			default: null
		},
		id: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		}, 
		placeholder: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'text'
		},
	})

	const emit = defineEmits([
		'update:modelValue'
	])

	// update modeled value
	const onInput = async ({ target }: any) => {
		emit('update:modelValue', target.value)
		await nextTick()
		return
	}
</script>