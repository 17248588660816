<template>
	<div>
		<TransitionRoot
			as="template"
			:show="sidebarOpen">
			<Dialog
				as="div"
				class="relative z-50 md:hidden"
				@close="sidebarOpen = false">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0">
					<div class="fixed inset-0 bg-gray-900/80 mb-16" />
				</TransitionChild>
  
				<div class="fixed inset-0 flex w-full">
					<TransitionChild
						as="template"
						enter="transition ease-in-out duration-300 transform"
						enter-from="translate-y-full"
						enter-to="-translate-y-0"
						leave="transition ease-in-out duration-300 transform"
						leave-from="-translate-y-0"
						leave-to="translate-y-full">
						<DialogPanel class="relative my-16 flex w-full flex-1 dark:bg-gray-900">
							<!-- Sidebar component, swap this element with another sidebar if you like -->
							<div class="flex grow flex-col overflow-y-auto bg-white px-6 pb-4 dark:bg-black/10 dark:ring-1 dark:ring-white/10 pt-10">
								<div v-if="showScenes">
									<div class="h-full">
										<template
											v-for="(scene, i) in sceneList"
											:key="i">
											<SceneCard
												:array-index="i"
												:scene="scene" />
										</template>
									</div>
									<div class="pb-8">
										<tw-button 
											@click="displayAdditionalInfo"  
											class="btn-brand font-medium  w-full">
											{{ t('welcome.continueButton') }}
										</tw-button>
									</div>
								</div>
								<div
									v-else-if="showAdditionalInfo"
									class="min-h-full flex flex-col gap-y-5 bg-white px-6 pt-4 pb-4 group/sidebar relative">
									<AdditionalInfo 
										@navigate-back="displayScenes" />
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>

	<div>  
		<!-- Static sidebar for desktop -->
		<div :class="['md:w-[358px] h-full hidden md:fixed md:inset-y-0 md:z-50 md:flex md:flex-col transition-all duration-200 ease-in-out shadow-lg border-r border-zinc-200']">
			<!-- Sidebar component, swap this element with another sidebar if you like -->
			<div class="flex h-full min-h-0 flex-col bg-white group/sidebar overflow-x-scroll">
				<div v-if="showScenes">
					<div class="flex flex-col justify-center h-16 px-6">
						<h1 class="text-xl font-semibold">
							{{ t('nav.sidebarTitle') }}
						</h1>
					</div>

					<div class="space-y-6 flex flex-1 flex-col overflow-y-auto px-6 pb-4">
						<div
							v-for="(scene, i) in sceneList"
							:key="i">
							<SceneCard
								ref="sceneCard"
								:array-index="i"
								:scene="scene" />
						</div>
					</div>
				</div>
				<div
					v-else-if="showAdditionalInfo"
					class="min-h-full flex flex-col gap-y-5 bg-white px-6 pt-4 pb-16 group/sidebar relative">
					<AdditionalInfo 
						@navigate-back="displayScenes" />
				</div>
			</div>
			<div 
				v-if="showScenes"
				class="border-t border-zinc-900/10 px-6 py-4 bg-white flex flex-col pb-20">
				<div class="flex justify-between items-center gap-6">
					<v-text class="w-full">
						{{ numScenesWithSong }} / {{ numScenes }} {{ t('scene.songsSelected') }}
					</v-text>
					<tw-button 
						@click="displayAdditionalInfo"  
						class="btn-brand font-medium w-full">
						{{ t('welcome.continueButton') }}
					</tw-button>
				</div>
			</div>
		</div>
  
		<!-- Main Content -->
		<div :class="['md:pl-[358px] transition-all duration-200 ease-in-out']">
			<main class="bg-gray-50 dark:bg-gray-900 h-screen">
				<div class="h-full">
					<!-- Your content -->
					<div class="min-h-full">
						<slot />
					</div>
					<PageFooter class="flex flex-col pb-16" />
				</div>
			</main>
			<div class="md:hidden fixed w-full bottom-0 z-50 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 md:px-8 dark:border-white/5 dark:bg-gray-900 justify-between">
				<v-text>
					{{ numScenesWithSong }} / {{ numScenes }} {{ t('scene.songsSelected') }}
				</v-text>
				
				<button
					type="button"
					class="-m-2.5 p-2.5 text-gray-700 md:hidden"
					@click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<ChevronUpIcon
						v-show="!sidebarOpen"
						class="h-6 w-6"
						aria-hidden="true" />
					<ChevronDownIcon
						v-show="sidebarOpen"
						class="h-6 w-6"
						aria-hidden="true" />
				</button>

				<!-- Push button to center -->
				<p />
				<p />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SidebarWithHeader' } // name the component
</script>

<script setup lang="ts">
	
	// Components
	import AdditionalInfo from '@/modules/search/components/AdditionalInfo.vue'
	import PageFooter from '@/components/PageFooter.vue'
	import SceneCard from '@/modules/scene/SceneCard.vue'
	
	// Headless UI
	import {
		Dialog,
		DialogPanel,
		Menu,
		MenuItem,
		TransitionChild,
		TransitionRoot,
	} from '@headlessui/vue'

	// Language
	import {
		useI18n
	} from 'vue-i18n'

	// Store
	import { 
		store as _jobStore
	} from '@/store/job'

	// Vue
	import {
		computed,
		ref,
		watch
	} from 'vue'

	const {t} = useI18n()
	const jobStore = _jobStore()

	const showScenes = ref<boolean>(true)
	const showAdditionalInfo = ref<boolean>(false)

	function displayScenes() {
		showAdditionalInfo.value = false
		showScenes.value = true
	}

	function displayAdditionalInfo() {
		showScenes.value = false
		showAdditionalInfo.value = true
	}

	const sceneList = computed(() => {
		return jobStore.job?.jobDetails?.scenes
	})
	const numScenes = ref(sceneList?.value?.length)
	const numScenesWithSong = ref(sceneList?.value?.filter(scene => scene['job__JOBS_SCENES__jobID::zctSongID'] !== '')?.length)

	watch(jobStore.job?.jobDetails?.scenes, () => {
		numScenesWithSong.value = sceneList?.value?.filter(scene => scene['job__JOBS_SCENES__jobID::zctSongID'] !== '')?.length
	})
  
	const sidebarOpen = ref(false)

  </script>